#ocean-backdrop {
  height: 32.9vw;
  margin-bottom: 0px;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 18.29vw;
  background: #fafafa;
  z-index: 10;
  transition: height 2s, padding-top 2s, background-position-y 2s, margin-bottom 2s, top 2s;
}

#ocean-backdrop-inner {
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 67%;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  transition: opacity 2s, background-position-y 2s;
}
#ocean-backdrop-inner.pwc {
  background-position-y: 95%;
}
.scrolled #ocean-backdrop-inner {
  opacity: 0;
  background-position-y: 100%;
}

.scrolled #ocean-backdrop {
  padding-top: 0px;
  height: 18.29vw;
  margin-bottom: 3.65vw;
  top: 0;
}
#ocean-backdrop .boat-side{
  transition: top 2s, opacity 2s;
  background-size: cover;
  background-repeat: no-repeat;
  width: 71.71vw;
  height: 26.46vw;
  position: absolute;
  right: -3.5vw;
  top: 6.09vw;
}
.scrolled #ocean-backdrop .boat-side{
  top: -2.44vw;
}
#left-info {
  z-index: 10;
  position: fixed;
  left: 2vw;
  top: 28vw;
  background: white;
  width: 35vw;
  padding: 2vw 1vw;
  transition: top 2s, padding 2s;
}
.scrolled #left-info {
  top: 2vw;
  padding: 1vw;
}

#product-name {
  line-height: 1;
  font-size: 5vw;
  transition: height 2s, padding-top 2s, background-position-y 2s, margin-bottom 2s, top 2s;
}
.scrolled #product-name {
  top: 22.5vw;
}

#color-swatches {
  z-index: 10;
  margin-top: 2vw;
  line-height: 1;
  transition: height 2s, padding-top 2s, background-position-y 2s, margin-bottom 2s, top 2s;
  font-size: 2vw;
}
.scrolled #color-swatches {
  top: 21.2vw;
}
.color-swatch {
  width: 3vw;
  height: 3vw;
  margin-right: 2vw;
}

#quick-specs {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 51vw;
  transition: height 2s, padding-top 2s, background-position-y 2s, margin-bottom 2s, top 2s;
}
.scrolled #quick-specs {
  top: 21.5vw;
}

#product-nav {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 67.8vw;
  transition: height 2s, padding-top 2s, background-position-y 2s, margin-bottom 2s, top 2s;
  height: 6.1vw;
  background: #d23532;
  text-align: left;
}
.scrolled #product-nav {
  top: 38vw;
}
#product-nav button {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 2.5vw;
  padding: 1.6vw 3vw;
}

.quick-spec-column {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 1.7vw;
  margin-top: 1.22vw;
  margin-bottom: 1.22vw;
  margin-left: -1px;
  float: left;
  height: 14.63vw;
  padding: 0 2.44vw;
  width: 16.66%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-spec-column-label, .available-in-text {
  text-transform: uppercase;
  font-size: 1.71vw;
  font-weight: bold;
}

.specs-header {
  border: .33vw solid #eee;
  border-bottom: 0;
  margin: 0px;
  text-align: left;
  padding: 1.5vw 2vw;
  font-weight: bold;
  text-transform: uppercase;
}

.specs-row {
  font-size: 1.71vw;
  border-left: .5vw solid #f1f1f1;
  border-right: .5vw solid #f1f1f1;
  background: #eee;
  text-align: left
}
.specs-column {
  display: inline-block;
  width: 50%;
  padding: 1.83vw;
  box-sizing: border-box;
}

.accessory {
  display: inline-block;
  width: 30.2vw;
  margin: 1vw 0vw 1vw 1vw;
  background: white;
  border: 2px solid #e6e6e6;
  float: left;
}
.accessory-image {
  height: 22vw;
  background-color: #fafafa;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.accessory-text {
  height: 10.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw;
}
#selected-accessory{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  top: 73.9vw;
  overflow: auto;
  transition: top 2s;
  padding-top: 1.5vw;
}
.scrolled #selected-accessory{
  top: 44.1vw;
}
