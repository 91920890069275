* { 
  box-sizing: border-box;
}
.App {
  text-align: center;
  font-family: 'Proxima Nova';
  font-size: 2vw;
  padding-top: 77vw;
  transition: padding-top 2s;
}
input {
  font-size: 2vw;
  border: .2vw solid #333;
}
button {
  font-size: 2vw;
}
.App.scrolled {
  padding-top: 77vw;
}

#cover {
  background: white;
  position: fixed;
  height: 73.9vw;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  transition: height 2s;
}

.scrolled #cover {
  height: 41.1vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#top-logo {
  background: url('../public/top-logo-big.jpg') no-repeat;
  background-size: contain;
  height: 13vw;
  width: 35%;
  position: fixed;
  z-index: 10;
  top: 1.3vw;
  left: 5%;
  transition: opacity 2s;
}
#centered-logo {
  background: url('../public/top-logo-big.jpg') no-repeat;
  background-size: contain;
  height: 13vw;
  width: 35%;
  margin: auto;
  z-index: 10;
  transition: opacity 2s;
}

.scrolled #top-logo {
  opacity: 0;
}

#choose {
  position: fixed;
  z-index: 10;
  top: 1vw;
  right: 5vw;
  width: 29.5vw;
  height: 16vw;
  font-size: 5.2vw;
  font-style: italic;
  color: #5a5a5b;
  font-family: "interstate";
  float: left;
  text-align: left;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -.065vw;
  transition: opacity 2s;
}

.scrolled #choose {
  opacity: 0;
}

#choose-text-red {
  padding-left: 2.25vw;
  color: #d23532;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#select-product {
  position: fixed;
  z-index: 100;
  top: 20vw;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  text-align: left;
}
.select-product-row {
  padding: 10px;
  border: 1px solid #ccc;
  margin: 10px;
}

#password-prompter{
  position: fixed;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  z-index: 1000;
}
#password-prompt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 30vw;
  background: white;
  z-index: 1000;
  font-size: 3vw;
}
#password-prompt input, #password-prompt button {
  font-size: 3vw;
}
#password-prompt input {
  width: 15vw;
  text-align: center;
}
#password-prompt button {
  width: 20vw;
}
