/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * interstate:
 *   - http://typekit.com/eulas/00000000000000003b9ae7f8
 *   - http://typekit.com/eulas/00000000000000003b9ae7f9
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-02-21 22:18:19 UTC"}*/
@font-face {
font-family:"interstate";
src:url("interstate-light-italic.woff") format("woff");
font-style:italic;font-weight:400;
}

@font-face {
font-family:"interstate";
src:url("interstate-light.woff") format("woff");
font-weight:400;
}


@font-face {
font-family:"interstate";
src: url("interstate.woff2") format("woff2"), url("interstate.woff") format("woff"),url("interstate.opentype") format("opentype");
font-style:normal;font-weight:700;
}

@font-face {
font-family:"interstate";
src:url("interstate-bold-italic.woff2") format("woff2"),url("interstate-bold-italic.woff") format("woff"),url("interstate-bold-italic.opentype") format("opentype");
font-style:italic;font-weight:700;
}

.tk-interstate { font-family: "interstate",sans-serif; }


@font-face{
  font-family:'Trade Gothic Bold Extended';
  src:url("trade-gothic.eot"), url("trade-gothic.woff2") format("woff2"),url("trade-gothic.woff") format("woff"),url(trade-gothic.ttf) format("truetype");
}

.trade-gothic {
  font-family: 'Trade Gothic Bold Extended';
}

@font-face{
font-family:'Proxima Nova';
src:url(proxima-nova-black-italic.eot);
src:url(proxima-nova-black-italic.woff2) format("woff2"),url(proxima-nova-black-italic.woff) format("woff");
font-weight:900;
font-style:italic
}

@font-face{
font-family:'Proxima Nova';
src:url(proxima-nova-bold.eot);
src:url(proxima-nova-bold.woff2) format("woff2"),url(proxima-nova-bold.woff) format("woff"),url(proxima-nova-bold.ttf) format("truetype");
font-weight:700;
font-style:normal
}

@font-face{
  font-family:'Proxima Nova';
src:url(proxima-nova.eot);
src:url(proxima-nova.woff2) format("woff2"),url(proxima-nova.woff) format("woff"),url(proxima-nova.ttf) format("truetype");
font-weight:400;
font-style:normal
}

@font-face{
  font-family:'Proxima Nova';
src:url(proxima-nova-italic.eot);
src:url(proxima-nova-italic.woff2) format("woff2"),url(proxima-nova-italic.woff) format("woff"),url(proxima-nova-italic.ttf) format("truetype");
font-weight:400;
font-style:italic
}

@font-face{
  font-family:'Proxima Nova';
  src:url(proxima-nova.eot);
  src:url(proxima-nova.woff) format("woff"),url(proxima-nova.ttf) format("truetype");
  font-weight:300;
  font-style:normal
}

@font-face{
  font-family:'Proxima Nova';
  src:url(proxima-nova-italic.eot);
  src:url(proxima-nova-italic.woff2) format("woff2"),url(proxima-nova-italic.woff) format("woff"),url(proxima-nova-italic.ttf) format("truetype");
  font-weight:300;
  font-style:italic
}
